<template>
  <div>
    <Navbar />
    <div class="root_scc">
      <!-- <validation-observer ref="simpleRules"> -->
      <b-form @submit.prevent="getCost">
        <div class="part1">
          <h1 class="title">
            Shipping Cost <span class="title-black">Calculator</span>
          </h1>

          <h2 class="h2">Where do you want your package to be shipped?</h2>

          <!-- <b-form-select v-model="country" :options="countries" class="input-select first"></b-form-select> -->

          <b-form-group>
            <select name="country" v-model="country" class="form-control" id="country">
              <option style="color:black;" value="0" label="Select a country ... " selected="selected">Select a
                country ... </option>
                  <option v-for="ctry in enabledCountries" style="color: black;" :value="ctry.countryCode" :label="ctry.countryName">{{ ctry.countryName }}</option>
            </select>
          </b-form-group>
          <div v-if="alertMessage" class="alert alert-danger">
            {{ alertMessage }}</div>

          <h2 class="package-type">Package Type</h2>

          <b-form-select class="input-select" v-model="type" :options="types"></b-form-select>

          <div class="weight">
      <div class="weight-first">
        <h2 class="weight-title">Package Weight</h2>
        <b-form-select v-model="weightType" :options="weightTypes" class="weight-select"></b-form-select>
        <b-form-input ref="weightInput" type="number" class="weight-input" id="input-1" v-model="form.weight" placeholder="0.00" min="0" max="120" step="any" @input="updateSlider" ></b-form-input>
      </div>
      <div class="weight-range">
        <b-form-input id="range-1" v-model="form.weight" type="range" max="120"></b-form-input>
      </div>
    </div>
          <div class="plant"></div>
          <!-- <img
          src="../../assets/images/shippingCostCalculator/plant.png"
        /> -->
          <section v-if="!showVolumetric">
            <b-button type="submit" 
             variant="no-color"
             v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
             class="mr-1 estimate-btn button">
              get an estimate
            </b-button>
            <!-- <span class="cost">INR | {{ finalPrice ? finalPrice : "" }}</span> -->
          </section>
        </div>
        <div class="part-2">

          <!-- grey col starts -->
          <div v-if="showVolumetric">

            <p class="note">*Note: Each dimension should be less than 120 cm</p>

            <div class="dimensions">
              <div class="box">
                <b-form-group id="input-group-1" label="Length" label-for="input-1" class="box-heading" label-size="lg">
                  <b-form-input type="number" id="input-1" v-model="form.length" placeholder="0.00" max="120" step="any" 

                    class="input-select box2"></b-form-input>
                </b-form-group>
              </div>
              <div class="box">
                <b-form-group id="input-group-1" label="Width" label-for="input-2" label-size="lg">
                  <b-form-input type="number" id="input-2" v-model="form.width" placeholder="0.00" max="120" step="any"
                    class="input-select box2" ></b-form-input>
                </b-form-group>
              </div>
              <div class="box">
                <b-form-group id="input-group-1" label="Height" label-for="input-3" label-size="lg">
                  <b-form-input type="number" id="input-3" v-model="form.height" placeholder="0.00" max="120" step="any"
                    class="input-select box2" ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- ESTIMATE BUTTON -->
            <section v-if="showVolumetric">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
                get an estimate
              </b-button>
              <!-- <span class="cost">INR | {{ finalPrice ? finalPrice : "" }}</span> -->
            </section>

            <!-- footer -->
            <div class="footer">
              <div class="footer_1">
                <div class="first-grid-col">
                  <!-- <HelperVue /> -->
                  <div class="root_helper">
                    <!-- 1st -->
                    <div class="h1">Shipping Partner Prices</div>
                    <div class="checkBoxes" v-for="(item, indx) in shippingPartners" :key="`${indx}`"
                      :class="[picked === item.id ? 'selected' : '']">
                      <!-- <div :id="`${item.id}`">{{ item.id }}</div> -->
                      <div :for="`${item.id}`" class="label_">
                        <div class="name special-border-2">{{ item.shippingpartners }}</div>
                        <!-- <div class="timeLine special-border-2"> -->
                        <!-- Timeline: {{ item.ts }}-{{ item.te }} weeks -->
                        <!-- </div> -->
                        <div class="blue">₹{{ finalPrice[`${item.id}`] }}</div>
                        <div v-if="la"></div>
                        <div v-else></div>
                      </div>
                    </div>
                    <!-- 2nd  -->
                    <div class="t2nd_helper">
                      <div class="h1">What is Volumetric Weight</div>
                      <div class="content_">
                        For the final weight we will calculate both the volumetric weight as
                        well as the actual weight of the shipment and choose whichever is higher
                      </div>
                      <div class="lst">
                        <span class="vw"> Volumetric Weight (kgs) = </span>
                        <div class="lst-parts">
                          <div class="lst-1">L x W x H</div>
                          <div class="lst2">5000</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="img-box">
                  <img src="../../assets/yicimages/shippingCostCalculator/bigBox.png" alt="box image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="terms" v-if="showVolumetric">
          <h1 class="heading_term">Notes and Terms &#38; Condition</h1>
          <ul class="terms_ul">
            <li>The cost of shipping a package is based on the space it occupies rather than the actual weight. It is calculated by multiplying the length(cm) x breadth(cm) x height(cm)/5000 which is the volumetric weight.</li>
            <li>The chargeable weight is always the greater of the two: Volumetric or the Actual weight. </li>
            <li>Maximum value of a shipment has to be less than INR 25,000.</li>
            <li>The charges are not inclusive of the IMPORT DUTY at the receiving country if applicable.</li>
          </ul>
        </div>
      </b-form>
    </div>
         <!-- footer -->
  <FooterYic/>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import Navbar from "../navbar/Navbar.vue";
import Ripple from "vue-ripple-directive";
import FooterYic from '../homepage/footer.vue';
import isoCountries from 'i18n-iso-countries';

export default {
  components: {
    Navbar,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    FooterYic,
  },

  data() {
    return {
      alertMessage: null,
      la: 0,
      form: {
        length: null,
        width: null,
        height: null,
        weight: 0,
      },
      country: "",
      countries: [{ value: "US", text: "US" }],
      type: "nonDocument",
      types: [
        // { value: "document", text: "Document" },
        { value: "nonDocument", text: "Non-Document" },
        // { value: "medicineAndSuplements", text: "Medicine and Supplements" },
      ],
      weightType: "kgs",
      weightTypes: [{ value: "kgs", text: "kgs" }],
      dimension: "centimeters",
      dimensions: [{ value: "centimeters", text: "centimeters" }],
      showVolumetric: true,
      // showVolumetric: false,
      picked: -1,
      price: {},
      getAnEstimateBtnClicked: false
    };
  },

  directives: {
    Ripple,
  },
  methods: {
    ...mapMutations({
      setWeight: "shippingcalculator/SET_WEIGHT",
      setVolumeDimensions: "shippingcalculator/SET_VOLUME_DIMENSIONS",
      setVolumePricingSelected:
        "shippingcalculator/SET_VOLUME_PRICING_SELECTED",
      setItemType: "shippingcalculator/SET_ITEMTYPE",
      setCountry: "shippingcalculator/SET_COUNTRY",
      setProjectedPrice: "shippingcalculator/SET_PROJECTED_PRICE",
    }),
    ...mapActions({
      calculatePrice: "shippingcalculator/calculateShippingPrice",
      fetchShippingPartners: 'shippingcalculator/fetchShippingPartners',
      getEnabledCountries: 'countriesSelection/getEnabledCountries',
    }),
    updateSlider() {
      // Ensure that the value is within the range of 0 to 120
      let newValue = parseFloat(this.form.weight) || 0;
      newValue = Math.min(Math.max(newValue, 0), 120);

      // Update the form.weight property
      this.form.weight = newValue;
    },
    updateWeightInput() {
      // Ensure that the value is within the range of 0 to 120
      let newValue = parseFloat(this.form.weight) || 0;
      newValue = Math.min(Math.max(newValue, 0), 120);

      // Update the form.weight property
      this.form.weight = newValue;
    },
    // ... (other methods)
    changeHandler() {
      this.form.weight = 0;
      this.form.length = 0;
      this.form.height = 0;
      this.form.width = 0;
      this.setProjectedPrice(0); //it helps setting project price to zero when user toggle showvolumetric
    },
    async getCost() {
  if (!this.country || this.country === "0") {
    // Country is not selected, show an alert message
    this.alertMessage = "Please select a country.";
    return;
  }

  // Clear any previous alert message
  this.alertMessage = null;

      // this.changeHandler();
      this.getAnEstimateBtnClicked = true
      this.setWeight(this.form.weight);
      this.setVolumeDimensions({
        length: this.form.length,
        width: this.form.width,
        height: this.form.height,
      });
      this.setCountry(this.country);
      this.setItemType(this.type);
      this.setVolumePricingSelected(this.showVolumetric);
      await this.calculatePrice({country: this.country});
      let x = this.la;
      this.la = Math.abs(1 - x);
      if (!this.country || this.country === "0") {
    this.alertMessage = "Please select a country.";
    return;
  }
  
    },
  },
  computed: {
    volume() {
      return this.form.width * this.form.height * this.form.length;
    },
    ...mapState({
      finalPrice: (state) => state.shippingcalculator.projectedPrice,
      shippingPartners: (state) => state.shippingcalculator.shippingPartners,
      enabledCountries: state => {
        return state.countriesSelection.enabledCountries
      }
    }),
  },
  async mounted() {
    isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    await this.fetchShippingPartners();
    await this.getEnabledCountries();
  }
};
</script>

<style scoped>
.root_scc {
  background: #fff;
  ;
}

.title {
  /* color: #4385f5; */
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 34px;
}

.title-black {
  color: #414141;
}

.part1 {
  background: #ffffff;
  padding: 3rem 6rem 1rem;
  /* background: #fff; */
}

.weight-title {
  margin: 0;
}

h2 {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 25px;
}

.input-select {
  margin-bottom: 4rem;
  width: fit-content;
  border: 1px solid #414141;
  font-size: large;
  height: fit-content;
}

.input-select.first {
  width: 40%;
}

.weight {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
  padding-right: 2rem;
  margin-bottom: 3rem;
}

.weight-range {
  display: flex;
  align-items: center;
  width: 90%;
  z-index: 10;
}

.weight-first {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.weight-select,
.weight-input {
  border: 1px solid #414141;
  width: 80px;
  font-size: 17px;
}

.weight-input {
  margin-left: -3rem;
}

section {
  width: 60%;
  display: flex;
  gap: 2rem;
}

button {
  background: #4385f5;
  color: #fff;
  padding: 1rem 2rem;
  font-size: 15px;
  text-transform: uppercase;
}

.cost {
  border: 1px solid #414141;
  display: flex;
  align-items: center;
  width: 3120px;
  padding: 1rem;
  font-size: 18px;
  border-radius: 0.358rem;
}

.part-2 {
  padding: 1.4rem 6rem 1rem;
}

.dimension {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.note {
  font-style: italic;
  font-size: 17px;
  line-height: 37px;
}

.dimensions {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 2rem;
  width: 90%;
  align-items: center;
  justify-content: space-between;
}

.box {
  width: 100%;
}

.box2 {
  width: 100%;
  margin-bottom: 1.9rem;
}

.footer {
  margin-top: 8rem;
  width: 100%;
}

.footer_1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.first-grid-col {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-right: 1px solid black;
  font-size: 20px;
  justify-content: center;
}

.img-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .plant {
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url("../../assets/yicimages/shippingCostCalculator/plant.png");
  background-repeat: no-repeat;
  height: 6120px;
  width: 460px;
  overflow: hidden;
} */

.terms {
  margin-top: 2rem;
  padding: 3rem 6rem;
  background: #ffffff;
  color: #414141;
}

.terms_ul {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  gap: 1rem;
  font-size: 16px;
}

.heading_term {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #414141;
  margin-bottom: 2rem;
}

.content_ {
  font-size: 16px;
  width: 374px;
  color: #414141;
  margin-bottom: 2rem;
}

.vw {
  font-weight: 500;
  line-height: 20px;
}

.lst {
  color: #414141;
  display: flex;
  gap: 0.5rem;
  text-align: center;
  width: 400px;
  height: 50px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 2rem;
}

.lst-1 {
  border-bottom: 1px solid #4b2d2d;
}

.h1 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #414141;
  margin-bottom: 1rem;
}

.checkBoxes {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.6rem;
  width: 450px;
  height: 42px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.label_ {
  cursor: default;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0.6rem 0;
  font-size: 16px;
}

.name {
  font-weight: 500;
  line-height: 18px;
  color: #414141;
  display: flex;
  align-items: center;
  justify-content: center;
}

.special-border-2 {
  border-right: 1px solid #bcbcbc;
}

.blue {
  font-weight: 500;
  color: #4385f5;
}

.label_ div {
  padding: 0 1rem;
}

.t2nd_helper {
  margin-top: 4rem;
}
.weightfield {
  display: flex;
  flex-direction: column;
}
.errMsg {
  margin-left: -3rem;
}

/* mobile screens */
@media screen and (max-width: 1200px){
  .root_scc{
    width: 100vw;
    height: 100%;
  }
  .part1{
    width: 100vw;
    padding: 2rem;
  }
  .part-2{
    width: 100vw;
    padding: 1.5rem;
    padding-top: 0rem;
    padding-bottom: 0;
  }
  .terms{
    width: 100vw;
    margin-top: 1rem;
    padding: 2rem;
    padding-top: 0;
  }
  .content_{
    font-size: 12px;
    width: 90vw;
  }
  .t2nd_helper{
    margin-top: 2rem;
  }
  .errMsg {
    margin-left: 0;
  }
  .h1{
    font-size: 18px;
  }
  .lst{
    font-size: 12px;
    width: 100vw;
  }
  .title{
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }
  .h2{
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  .input-select{
    margin-bottom: 1.5rem;
  }
  .weight{
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .dimensions{
    margin-top: 0;
    margin-bottom: 0;
  }
  .footer{
    margin-top: 2rem;
  }
  .package-type, .weight-title{
    font-size: 1.3rem;
  }
  .weight-input{
    margin: 0;
  }
  .heading_term{
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .terms_ul{
    font-size: 12px;
  }
  .estimate-btn{
    background: #4385f5;
  }
  .btn-primary{
    background-color: #4385f5 !important;
    padding: 1rem;
  }
  .img-box {
    display: none;
  }
  .checkBoxes{
    width: fit-content;
  }
  .custom-slider .slider-track-class {
  width: 80%; /* Adjust this value to make the slider longer visually */
}
}
</style>